import { Home } from './pages/Home';
import { ArticlePreview } from './pages/ArticlePreview';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/article/:contentId" element={<ArticlePreview />} />
      </Routes>
    </Router>
  );
}

export default App;
