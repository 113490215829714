import { Container } from '@chakra-ui/react';
import { getArticleData } from '../../utils/article';

export const Article = ({ entry }) => {
  return (
    <Container overflow="scroll" paddingBottom={10}>
      {!!entry && (
        <div
          dangerouslySetInnerHTML={{
            __html: getArticleData(entry),
          }}
        />
      )}
    </Container>
  );
};
