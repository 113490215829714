import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const getHtml = (htmlTitle, htmlBody) => {
  const fullHtml = `
      <h1 style="text-align:center; font-weight: bold; font-size: 2rem; margin: 10px;">${htmlTitle}</h1>
      <p style="margin: 10px;">${htmlBody}</p>
  `;

  return fullHtml;
};

export const getArticleData = (selectedContentTypeEntry) => {
  const isArticleContentType =
  selectedContentTypeEntry?.sys?.contentType?.sys?.id === 'article'
    
  const options = {
    renderNode: {
      'embedded-asset-block': (node) =>
        `<img alt="" src="${node.data.target.fields.file.url}"/>`,
    },
  };
  const html = documentToHtmlString(
    isArticleContentType ? selectedContentTypeEntry.fields.body : '',
    options
  );

  return getHtml(
    isArticleContentType
      ? selectedContentTypeEntry.fields.title
      : '',
    html
  );
};
