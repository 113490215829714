import { Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Article } from '../../components/Article';
import { PageWrapper } from '../../components/PageWrapper';
import { getPreviewContentById } from '../../services/queryContentful';

export const ArticlePreview = () => {
  const { contentId } = useParams();
  const [previewContent, setPreviewContent] = useState(undefined);
  const [isLoadingEntry, setIsLoadingEntry] = useState(false);

  useEffect(() => {
    const fetchContentTypeById = async (id) => {
      setIsLoadingEntry(true);
      setPreviewContent(await getPreviewContentById(id));
      setIsLoadingEntry(false);
    };
    if (contentId) {
      fetchContentTypeById(contentId);
    }
  }, [contentId]);

  return (
    <PageWrapper>
      {isLoadingEntry ? (
        <Spinner m="50%" size="lg" />
      ) : (
        <Article entry={previewContent} />
      )}
    </PageWrapper>
  );
};
