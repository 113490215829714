import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';

export const ContentEntries = ({ entries, handleContentEntrySelection }) => {
  return (
    <TableContainer p={2} overflowY="scroll" maxWidth="95vw">
      <Table>
        <Thead>
          <Tr>
            <Th>Select Content To Preview:</Th>
          </Tr>
        </Thead>
        <Tbody>
          {entries.map(
            (entry) =>
              entry?.fields?.title && (
                <Tr onClick={() => handleContentEntrySelection(entry?.sys?.id)} key={entry?.sys?.id}>
                  <Td>{entry.fields.title}</Td>
                </Tr>
              )
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
