import * as contentful  from 'contentful';


class ContentfulPreviewAuthenticationService {
  contentfulPreviewClient = contentful.createClient({
    accessToken: process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN,
    space: process.env.REACT_APP_CONTENTFUL_TARGET_SPACE,
    host: 'preview.contentful.com',
    environment: process.env.REACT_APP_CONTENTFUL_ENV
  });
}

export const contentfulPreviewClient = async () => {
  try {
    const CAS = new ContentfulPreviewAuthenticationService();
    return CAS.contentfulPreviewClient;
  } catch (error) {
    console.error(error);
  }
};
