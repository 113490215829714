import { Flex } from '@chakra-ui/layout';
import { Link } from 'react-router-dom';


export const Nav = () => {
  return (
    <Flex bg="#003033" height={12} p={5} w="100vw" color="whiteAlpha.900" alignItems="center" position="fixed" top={0}>
      <Link to="/">Fern Content Preview</Link>
    </Flex>
  );
};
