import { useEffect, useState } from 'react';
import { Spinner } from '@chakra-ui/spinner';
import { useNavigate } from "react-router-dom";
import { PageWrapper } from '../../components/PageWrapper';
import { ContentEntries } from '../../components/ContentEntries';
import {
  getPreviewContentTypeEntries,
} from '../../services/queryContentful';

export const Home = () => {
  const navigate = useNavigate();
  const [contentTypeEntries, setContentTypeEntries] = useState([]);
  const [isLoadingEntries, setIsLoadingEntries] = useState(false);

  useEffect(() => {
    fetchContentTypeEntries()
  }, []);

  const fetchContentTypeEntries = async () => {
    setIsLoadingEntries(true);
    const entries = await getPreviewContentTypeEntries("article")
    setContentTypeEntries(entries);
    setIsLoadingEntries(false);
  };

  const handleContentEntrySelection = contentId => {
    navigate(`/article/${contentId}`)
  }

  return (
    <PageWrapper>
      {
        isLoadingEntries ?
        <Spinner m="50%" size="lg" /> :
        <ContentEntries entries={contentTypeEntries} handleContentEntrySelection={handleContentEntrySelection} /> 
      }
    </PageWrapper>
  );
};
