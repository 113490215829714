import { contentfulPreviewClient } from '../contentfulClient';

export const getAllPreviewContentTypes = async () => {
  try {
    const client = await contentfulPreviewClient();
    const { items } = await client.getContentTypes();
    return items;
  } catch (error) {
    console.error(error);
  }
};

export const getPreviewContentById = async (entryId) => {
  try {
    const client = await contentfulPreviewClient();
    return client.getEntry(entryId);
  } catch (error) {
    console.error(error);
  }
};

export const getPreviewContentTypeEntries = async (contentType) => {
  try {
    const fieldsQuery = {
      content_type: contentType,
    };

    const client = await contentfulPreviewClient();
    const response = await client.getEntries(fieldsQuery);
    return response.items;
  } catch (error) {
    console.error(error);
  }
};
