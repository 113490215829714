import { Container, Flex } from '@chakra-ui/react';
import { Nav } from '../Nav';

export const PageWrapper = ({ children }) => {
  return (
    <Flex h="100vh" w="100vw" flexDirection="column">
      <Nav />
      <Container mt="60px">{children}</Container>
    </Flex>
  );
};
